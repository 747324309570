<template>
  <DashboardVideo />
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-between g-0">
      <div class="col-12 ps-3 pe-3 d-flex align-items-center justify-content-end theme-header">
        <router-link :to="{ name: 'DashboardHome' }" class="btn-back"></router-link>
        <a href="#" class="m-auto"><img :src="`/img/ui-theme/` + theme.devLogo" class="devlogo"/></a>
        <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><img src="img/ui-theme/contact-icon-rev.svg" alt="Fullscreen"></button>
      </div>
      <div class="col-12 d-flex align-items-center justify-content-center z-2 pb-40-vh">
        <div class="row g-0">
          <div class="col-12">
            <button class="btn-theme-menu mb-2" @click="purge">Purge Cache</button>
            <span class="status" v-if="status">{{ status }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex align-items-end justify-content-end sticky-bottom theme-footer">
        <img :src="`/img/ui-theme/` + theme.agentLogo" class="position-relative theme-footer-logo"/>
      </div>
    </div>
  </div>
  <OffCanvas />
</template>

<script>
import DashboardVideo from "@/components/DashboardVideo.vue";
import OffCanvas from "@/components/OffCanvas.vue";
import global from '../global'
import BunnyNetService from "@/services/BunnyNetService.js";

export default {
  name: "Admin",
  components: {
    DashboardVideo,
    OffCanvas
  },
  data() {
    return {
      status: null,
    }
  },
  created() {
  },
  methods: {
    purge: function() {
      BunnyNetService.purgeCache()
      .then(response => {
        if (response.status === 200) this.status = "Successfully purged cache";
      })
      .catch(error => {
        console.log(error)
      })
    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>

<style scoped>
.status {
  width: 400px;
  text-align: center;
  margin: auto;
  display: block;
  background: rgb(51 129 51 / 75%);
  padding: 8px;
}
</style>

