import { reactive } from "vue";

const theme = reactive({
  agentLogo: "footer-logo.svg",
  devLogo: "logo.png",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "4a009d51-7963-4723-839d-1882797db447",
  videoLibraryId: "259114",
  streamCdnUrl: "https://vz-f90c8dc0-599.b-cdn.net",
  streamApiKey: "af344282-dba4-45df-8b5e88e26382-6bfd-4686",
  storageApiPassword: "e88a9df9-c032-4522-b1fcfdff985f-db14-4b3d",
  cdnUrl: "https://nera.b-cdn.net",
  devFolder: "/nera",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

  // top level menu
  assetsMenuOrder: [
    'amenities',
    'exterior-building',
  ],

  bed2MprNorthMenuOrder: [
    'video',
    '360',
    'floorplan',
    'renders'
  ],
  bed2MprSouthMenuOrder: [
    'video',
    '360',
    'floorplan',
    'renders'
  ],
  amenitiesMenuOrder: [
    'video',
    '360',
    'floorplan',
    'renders'
  ],
  exteriorBuildingMenuOrder: [
    'video',
  ],

  bed2MprNorthAspect: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      '2 Bed MRP North Aspect'
    ],
    panoramasUrl: [
      'https://storage.net-fs.com/hosting/7566552/23/'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },
  exteriorBuilding: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      ''
    ],
    panoramasUrl: [
      ''
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  amenities: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      ''
    ],
    panoramasUrl: [
      ''
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

  lobbyAsset: {
    videos: [],
    videosName: [],
    panoramas: [0],
    panoramasName: [
      'Lobby'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/7URp-vZfj'
    ],
    renders: [],
    rendersName: [],
    rendersUrl: [],
    floorplans: [],
    floorplansName: [],
    floorplansUrl: [],
    range: [],
    rangeName: [],
    rangeUrl: [],
  },

});

export default { theme };
