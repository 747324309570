<template>
  <div class="container d-flex align-items-center justify-content-center z-2">
    <div class="row mobile align-content-unset g-0" style="overflow: hidden;">
      <TypeTopNav :heading="this.assetType" />

      <div class="col-12 d-flex align-items-flex-start justify-content-center z-2">
        <img src="/img/ui-theme/placeholder.png" className="w-100"/>
      </div>

      <TypeBotNav />
    </div>
  </div>
  <OffCanvas />

</template>

<script>
import global from "../global";
import TypeTopNav from '@/components/TypeTopNav.vue';
import TypeBotNav from '@/components/TypeBotNav.vue';
import OffCanvas from "@/components/OffCanvas.vue";

export default {
  name: "PlaceHolderView",
  props: {
    assetType: String
  },
  components: {
    TypeTopNav,
    TypeBotNav,
    OffCanvas
  },
  data() {
    return {
    }
  },
  created() {
   
  },
  mounted() {
   
  },
  methods: {
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>
