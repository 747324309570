import { createRouter, createWebHistory } from "vue-router";
import DashboardHome from "../views/DashboardHome.vue";
import DefaultView from "../views/DefaultView.vue";
import Admin from "../views/Admin.vue";
import RegisterUser from "../views/RegisterUser.vue";
import Login from "../views/Login.vue";
import AssetType from "../views/AssetType.vue";
import AssetView from "../views/AssetView.vue";
import VideoView from "../views/VideoView.vue";
import RenderView from "../views/RenderView.vue";
import AssetViewDetail from "../views/AssetViewDetail.vue";
import PanoramaView from "../views/PanoramaView.vue";
import FloorplanView from "../views/FloorplanView.vue";
import NotFoundComponent from "../views/NotFoundComponent.vue";
import TopLevelPdfViewDetail from "../views/TopLevelPdfViewDetail.vue";
import PlaceHolderView from "../views/PlaceHolderView.vue"
import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "DashboardHome",
    component: DashboardHome,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/default",
    name: "DefaultView",
    component: DefaultView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/admin",
    name: "Admin",
    props: true,
    component: Admin,
  },
  {
    path: "/asset/:assetType",
    name: "AssetType",
    props: true,
    component: AssetType,
  },
  {
    path: "/asset/:assetType/:viewType/:lid?/:collectionId?",
    name: "AssetView",
    props: true,
    component: AssetView,
  },
  {
    path: "/asset/view/video/:collectionId/:guid",
    name: "VideoView",
    props: true,
    component: VideoView,
  },
  {
    path: "/asset/:assetType/:viewType/view/:order",
    name: "AssetViewDetail",
    props: true,
    component: AssetViewDetail,
  },
  {
    path: "/brochure",
    name: "TopLevelPdfViewDetail",
    props: true,
    component: TopLevelPdfViewDetail,
  },
  {
    path: "/asset/:assetType/:viewType/view/:order",
    name: "RenderView",
    props: true,
    component: RenderView,
  },
  {
    path: "/asset/:assetType/:viewType/view/:order",
    name: "PanoramaView",
    props: true,
    component: PanoramaView,
  },
  {
    path: "/asset/:assetType/:viewType/view/:order",
    name: "FloorplanView",
    props: true,
    component: FloorplanView,
  },
  {
    path: "/asset/:assetType/placeholder",
    name: "PlaceHolderView",
    props: true,
    component: PlaceHolderView,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterUser,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent,
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/login' && auth.currentUser) {
    next('/')
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next('/login')
    return;
  }

  next();
})

export default router;
